/* General styles */
body {
    font-family: 'Spectral', serif;
}

.arts-container {
    background-color: white;
    padding: 20px;
}

.left-container {
    background-color: #f8f9fa; /* Light background for better visibility */
    min-width: 250px; /* Ensure minimum width to prevent text wrapping */    flex: 0 0 195px; /* Ensure the sidebar maintains the minimum width */
    padding: 15px;
}

/* Hamburger toggle button */
.toggle-btn {
    position: absolute;
    top: 20px;
    left: 10px;
    z-index: 1050;
    display: inline-block;
    background-color: rgb(219, 219, 219);
    border: none;
    width: 60px; /* Fixed width to keep it square */
    height: 55px; /* Fixed height to keep it square */
    font-size: 1.5rem;
    text-align: center;    color: #247f8d;
    cursor: pointer;
}

/* Sidebar collapsed state */
.left-container.collapsed {
    min-width: 0;
    max-width: 0;
    overflow: hidden;
    transition: max-width 0.3s ease-in-out;
}

/* Sidebar expanded state */
.left-container {
    transition: max-width 0.3s ease-in-out;
}

/* Header and navigation styles */
.name {
    font-size: 40px !important;
    font-weight: 300 !important;
    margin: 30px 0;
    color: #247f8d;
    font-family: 'DIN Medium', "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.header {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0;
}

.header a {
    font-family: din_mediumregular, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 40px;
    font-weight: 300;
}

.home-link {
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
    color: black;
}

.nav-item .title {
    font-size: 1.35rem; /* Larger font size for titles */
    margin-bottom: 0rem; /* Space below titles */
    color: black;
}

.nav-item {
    margin-bottom: 2rem; /* Space between items */
}

.title:hover {
    color: #247f8d;
}

.subtitle-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Space between subtitle items */
    padding-left: 0; /* Remove any extra indentation */
}

.subtitle {
    height: auto; /* Remove fixed height to adapt to content */
}

.subtitle-list .subtitle {
    font-size: 1rem; /* Smaller font size for subtitles */
    margin-bottom: 0rem !important; /* Space below subtitles */
    height: 40px !important;
}

.subtitle-list .subtitle a {
    color: gray; /* Different color for subtitles */
}

.subtitle a {
    padding: .5rem 1rem; /* Add padding for clickable area */
    display: inline-block;
    width: 100%; /* Ensure hover effect spans the full width */
}

.subtitle a:hover {
    color: #247f8d;
}

.min-vh-100 {
    min-height: 100vh;
}

.nav-link,
.nav-link span {
    display: inline-flex;
    align-items: center; /* Vertically align text */
}

#menu {
    margin-bottom: 100px !important;
}

/* Photography container styles */
.photography-container { 
    padding: 50px 50px;
}
.photography-container h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
    color: #0f5b67;
}

.photos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px 0;
}

/* Image row styles */
.image-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.image-row .col-12 {
    flex: 1;
    display: flex;
    justify-content: center;
}

.img-fluid {
    max-width: 100%;
    height: auto;
    object-fit: cover; /* Ensures the image covers the container without distortion */
    cursor: pointer;
}

/* Modal styles */
.modal {
    display: block;
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.9);
}

.modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor:pointer;
}




